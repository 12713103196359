const MAX_BACKLOG_SIZE = 200;
let isLoggingEnabled = false;
let logBacklog: Array<{ method: 'log' | 'info' | 'warn' | 'error'; message: string; params: unknown[] }> = [];

// Store original console methods
const originalConsoleMethods: Record<'log' | 'info' | 'warn' | 'error', ((...data: unknown[]) => void) | undefined> = {
    log: console.log.bind(console),
    info: console.info.bind(console),
    warn: console.warn.bind(console),
    error: console.error.bind(console),
};

function formatLogMessage(message: string): string {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    const milliseconds = now.getMilliseconds().toString().padStart(3, '0');
    const timestamp = `${hours}:${minutes}:${seconds}:${milliseconds}`;

    return `[${timestamp}] ${message}`;
}

export function logWithTimestamp(message: string, ...optionalParams: unknown[]) {
    const formattedMessage = formatLogMessage(message);

    if (isLoggingEnabled) {
        (originalConsoleMethods.log ?? console.log).call(console, formattedMessage, ...optionalParams);
    } else {
        if (logBacklog.length >= MAX_BACKLOG_SIZE) {
            logBacklog.shift();
        }
        logBacklog.push({ method: 'log', message: formattedMessage, params: optionalParams });
    }
}

export function setLoggingEnabled(enabled: boolean) {
    isLoggingEnabled = enabled;

    if (isLoggingEnabled && logBacklog.length > 0) {
        (originalConsoleMethods.log ?? console.log).call(console, '--- Outputting log backlog ---');
        logBacklog.forEach(({ method, message, params }) => {
            (originalConsoleMethods[method] ?? console[method]).call(console, message, ...params);
        });
        (originalConsoleMethods.log ?? console.log).call(console, '--- End of log backlog ---');
        logBacklog = [];
    }
}

export function getIsLoggingEnabled(): boolean {
    return isLoggingEnabled;
}

export function wrapConsoleMethod(
    method: 'log' | 'info' | 'warn' | 'error',
    callback?: (message: string, ...optionalParams: unknown[]) => void,
) {
    return function (message: string, ...optionalParams: unknown[]) {
        const formattedMessage = formatLogMessage(message);

        if (isLoggingEnabled) {
            (originalConsoleMethods[method] ?? console[method]).call(console, formattedMessage, ...optionalParams);
        } else {
            if (logBacklog.length >= MAX_BACKLOG_SIZE) {
                logBacklog.shift();
            }
            logBacklog.push({ method, message: formattedMessage, params: optionalParams });
        }

        if (callback) {
            callback(message, ...optionalParams);
        }
    };
}
