import { AuthType } from './libs/auth/AuthHelper';

const checkVariables = (variables: string[], missingVariables: string[]) => {
    for (const variable of variables) {
        if (!process.env[variable]) {
            missingVariables.push(variable);
        }
    }
};

/**
 * Checks if all required environment variables are defined
 * @returns {string[]} An array of missing environment variables
 */
export const getMissingEnvVariables = () => {
    // Should be aligned with variables defined in .env.example
    const envVariables = ['REACT_APP_BACKEND_URI'];
    const missingVariables: string[] = [];

    checkVariables(envVariables, missingVariables);

    if (process.env.REACT_APP_AUTH_TYPE === AuthType.OIDC) {
        checkVariables(
            [
                'REACT_APP_OPENIDCONNECT_METADATA_URL',
                'REACT_APP_OPENIDCONNECT_AUTHORITY',
                'REACT_APP_OPENIDCONNECT_CLIENT_ID',
                'REACT_APP_OPENIDCONNECT_REDIRECT_URI',
                'REACT_APP_OPENIDCONNECT_API_SCOPE',
            ],
            missingVariables,
        );
    } else if (process.env.REACT_APP_AUTH_TYPE !== AuthType.None) {
        missingVariables.push('REACT_APP_AUTH_TYPE');
    }

    return missingVariables;
};
