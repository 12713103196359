import { BackendServiceUrl, BaseService } from './BaseService';
import { FeedbackData } from '../models/FeedbackData';
import { HostContext } from '../../embed-script/HostContext';

export class FeedbackService extends BaseService {
    constructor(accessToken: string, baseServiceUrl: string = BackendServiceUrl) {
        super(accessToken, baseServiceUrl);
    }

    public async postFeedbackAsync(data: FeedbackData, hostContext?: HostContext) {
        await this.getResponseAsync({
            commandPath: 'feedback',
            method: 'POST',
            body: data,
            hostContext,
        });
    }
}
