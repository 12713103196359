import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/app/store';
import { setLoggingEnabled } from '../libs/logging';

export const FeatureFlagInitializer: React.FC = () => {
    const featureFlags = useSelector((state: RootState) => state.app.serviceInfo.featureFlags);

    useEffect(() => {
        if (featureFlags.FrontendDebugLogEnabled) {
            setLoggingEnabled(true);
        }
    }, [featureFlags]);

    return null; // This component doesn't render anything
};
