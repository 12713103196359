import { Button, Image, Title3 } from '@fluentui/react-components';
import React from 'react';
import vismaSignInLogo from '../../visma-logo.svg';
import { useSharedClasses } from '../../styles';

import { t } from 'i18next';
import { AuthHelper, AuthType } from '../../libs/auth/AuthHelper';
import { useAuth } from 'react-oidc-context';

export const Login: React.FC = () => {
    const classes = useSharedClasses();
    const auth = useAuth();

    if (AuthHelper.getAuthType() === AuthType.None) {
        return null;
    }

    let buttonAppearance: 'transparent' | undefined;
    let signInHandler, signInImage;

    if (AuthHelper.getAuthType() === AuthType.OIDC) {
        buttonAppearance = undefined;
        signInHandler = () => {
            void auth.signinRedirect();
        };
        signInImage = <Image src={vismaSignInLogo} />;
    }
    return (
        <div className={classes.informativeView}>
            <Title3>{t('login.title')}</Title3>

            <Button
                style={{ padding: 20 }}
                appearance={buttonAppearance}
                onClick={signInHandler}
                data-testid="signinButton"
            >
                {signInImage}
            </Button>
        </div>
    );
};
