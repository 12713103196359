import { useCallback, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../redux/app/hooks';
import { setHostContext } from '../../redux/features/hostContext/hostContextSlice';
import { useChat } from './useChat';
import { HostContext } from '../../embed-script/HostContext';
import { RootState } from '../../redux/app/store';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import * as tracking from '../../libs/tracking';
import { AppState } from '../../AppState';
import { TFunction } from 'i18next';

interface UseMessageHandlerProps {
    setAppState: (state: AppState) => void;
    i18n: {
        changeLanguage: (lang: string) => Promise<TFunction>;
    };
    setShowCsat?: (show: boolean) => void;
}

interface HostContextMessageData {
    event: string;
    data: unknown;
}

export const useMessageHandler = ({ setAppState, i18n, setShowCsat }: UseMessageHandlerProps) => {
    const dispatch = useAppDispatch();
    const chat = useChat();

    // Get access to chat messages from Redux store
    const chatMessages = useAppSelector(
        (state: RootState) =>
            (
                state.conversations.conversations[state.conversations.selectedId] as
                    | (typeof state.conversations.conversations)[string]
                    | undefined
            )?.messages,
    );
    const enableCsatSurvey = useAppSelector((state) => state.app.serviceInfo.featureFlags.EnableCsatSurvey);

    const setHostContextState = useCallback(
        (newHostContext: HostContext) => {
            dispatch(setHostContext(newHostContext));
        },
        [dispatch],
    );

    const handleContextChange = useCallback(
        async (data: HostContext) => {
            console.log('Context changed:', data);
            setHostContextState(data);

            if (data.language) {
                try {
                    await i18n.changeLanguage(data.language);
                    if (chatMessages?.length === 1) {
                        await chat.loadChats(data);
                        setAppState(AppState.Chat);
                    }
                } catch (e) {
                    console.error('Error handling context change:', e);
                }
            }
            localStorage.removeItem('hostContext');
        },
        [setHostContextState, i18n, chat, setAppState, chatMessages],
    );

    const handleCsatCheck = useCallback(async () => {
        if (!setShowCsat) return;

        const shouldShow = await chat.shouldShowCsat();

        if (shouldShow && enableCsatSurvey) {
            setShowCsat(true);
            window.parent.postMessage({ event: 'showCsat' }, '*');
        } else {
            window.parent.postMessage({ event: 'skipCsat' }, '*');
        }
    }, [chat, setShowCsat, enableCsatSurvey]);

    const handleMessage = useCallback(
        (e: MessageEvent<HostContextMessageData>) => {
            switch (e.data.event) {
                case 'aiassitantcontextchanged': {
                    const jsonString = e.data.data as string;
                    const data = JSON.parse(jsonString) as HostContext;
                    void handleContextChange(data);
                    break;
                }
                case 'aiassitantcontextupdate': {
                    const data = e.data.data as HostContext;
                    void handleContextChange(data);
                    break;
                }
                case 'aiassitanttrackevent': {
                    const data = e.data.data as IEventTelemetry;
                    tracking.trackEvent(data.name, data.properties);
                    break;
                }
                case 'checkCsat': {
                    void handleCsatCheck();
                    break;
                }
            }
        },
        [handleContextChange, handleCsatCheck],
    );

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [handleMessage]);
};
