import { useAuth } from 'react-oidc-context';
import { useMemo } from 'react';
import { FeedbackService } from '../services/FeedbackService';
import { FeedbackData } from '../models/FeedbackData';
import { RootState } from '../../redux/app/store';
import { useSelector } from 'react-redux';

export const useFeedback = () => {
    const auth = useAuth();
    const token = auth.user?.access_token;
    const hostContext = useSelector((state: RootState) => state.hostContext.hostContext);

    const feedbackService = useMemo(() => new FeedbackService(token ?? ''), [token]);

    const postFeedback = async (data: FeedbackData) => {
        const url1 = 'https://hooks.zapier.com/hooks/catch/4443939/3rdljtv/';

        try {
            const response1 = await fetch(url1, {
                method: 'POST',
                body: JSON.stringify(data),
            });

            const result1 = (await response1.json()) as Record<string, unknown>;
            await feedbackService.postFeedbackAsync(data, hostContext);

            return result1;
        } catch (error) {
            throw error;
        }
    };

    return {
        postFeedback,
    };
};
