import { FC, useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import {
    Avatar,
    Menu,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Persona,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { useChat } from '../../libs/hooks/useChat';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState, resetState } from '../../redux/app/store';
import { HostContext } from '../../embed-script/HostContext';
import { LanguageSwitcher } from './LanguageSwitcher';

export const useClasses = makeStyles({
    root: {
        marginRight: tokens.spacingHorizontalXL,
    },
    persona: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingVerticalMNudge),
        overflowWrap: 'break-word',
    },
});

interface IUserSettingsProps {
    setLoadingState: () => void;
    hostContext?: HostContext;
}

export const UserSettingsMenu: FC<IUserSettingsProps> = ({ setLoadingState, hostContext }) => {
    const classes = useClasses();
    const chat = useChat();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const auth = useAuth();

    const onLogout = useCallback(() => {
        setLoadingState();
        void auth.signoutRedirect();
        resetState();
    }, [auth, setLoadingState]);

    const { t } = useTranslation();

    const onNewChat = useCallback(() => {
        setLoadingState();
        chat.createChat(hostContext)
            .then(() => {})
            .catch(() => {});
    }, [chat, setLoadingState, hostContext]);

    return (
        <>
            {
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        {
                            <Avatar
                                className={classes.root}
                                key={activeUserInfo?.username}
                                name={activeUserInfo?.username}
                                color={'brand'}
                                size={28}
                                badge={undefined}
                                data-testid="userSettingsButton"
                            />
                        }
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <Persona
                                className={classes.persona}
                                name={activeUserInfo?.username}
                                secondaryText={activeUserInfo?.email}
                                presence={undefined}
                                avatar={{ color: 'brand' }}
                            />
                            <MenuDivider />
                            <MenuItem data-testid="createChatMenuButton" onClick={onNewChat}>
                                {t('userSettingsMenu.newChat')}
                            </MenuItem>
                            <LanguageSwitcher />
                            <MenuItem data-testid="logOutMenuButton" onClick={onLogout}>
                                {t('userSettingsMenu.logOut')}
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>
            }
        </>
    );
};
