import React from 'react';
import 'flag-icons/css/flag-icons.min.css';

interface LanguageFlagProps {
    countryCode: string;
    className?: string;
    square?: boolean;
}

export const LanguageFlag: React.FC<LanguageFlagProps> = ({ countryCode, className = '', square = false }) => {
    const flagClasses = ['fi', `fi-${countryCode}`, square ? 'fis' : '', className].filter(Boolean).join(' ');

    return <span className={flagClasses} />;
};
