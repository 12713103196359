import { FluentProvider, Subtitle1, makeStyles, shorthands, tokens } from '@fluentui/react-components';

import * as React from 'react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSettingsMenu } from './components/header/UserSettingsMenu';
import { WelcomeDialog } from './components/shared/WelcomeDialog';
import { BackendProbe, ChatView, Error, Loading, Login } from './components/views';
import { AuthHelper, AuthType } from './libs/auth/AuthHelper';
import { useAppDispatch, useAppSelector } from './redux/app/hooks';
import { RootState } from './redux/app/store';
import { setEmbeddedMode, setServiceInfo, setShowCsat } from './redux/features/app/appSlice';
import { semanticKernelLightTheme } from './styles';
import '/node_modules/flag-icons/css/flag-icons.min.css';

import { AppState } from './AppState';
import logo from './assets/Visma_spcs_logo_vanilla.svg';
import './i18n';
import { HostContext } from './embed-script/HostContext';
import { HostContextSelector } from './components/header/HostContextSelector';
import { useMessageHandler } from './libs/hooks/useMessageHandler';
import { CSATDialog } from './components/shared/CSATDialog';
import { useAuth } from 'react-oidc-context';
import { setHostContext } from './redux/features/hostContext/hostContextSlice';
import { useChat } from './libs/hooks/useChat';

export const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
    },
    header: {
        alignItems: 'center',
        backgroundColor: '#F56262', // tokens.colorBrandForeground2,
        color: tokens.colorNeutralForegroundOnBrand,
        display: 'flex',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalM,
            display: 'flex',
            ...shorthands.flex(1),
        },
        '& img': {
            display: 'flex',
            width: '150px',
        },
        height: '48px',
        justifyContent: 'space-between',
        width: '100%',
    },
    persona: {
        marginRight: tokens.spacingHorizontalXXL,
    },
    cornerItems: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
});

const App: FC = () => {
    const classes = useClasses();
    const auth = useAuth();

    const dispatch = useAppDispatch();

    const [appState, setAppState] = React.useState(AppState.SettingUserInfo);

    const { hostContext, isInitialized: hostContextInitialized } = useAppSelector(
        (state: RootState) => state.hostContext,
    );
    const setHostContextState = React.useCallback(
        (newHostContext: HostContext) => {
            dispatch(setHostContext(newHostContext));
        },
        [dispatch],
    );

    React.useEffect(() => {
        if (hostContextInitialized) {
            return;
        }

        const hostContextJsonFromUrl = new URLSearchParams(window.location.search).get('hostContext');
        let hostContextFromUrl: HostContext | undefined;
        if (hostContextJsonFromUrl) {
            const url = new URL(window.location.href);
            url.searchParams.delete('hostContext');
            window.history.replaceState({}, '', url.toString());
            try {
                hostContextFromUrl = JSON.parse(hostContextJsonFromUrl) as HostContext;
            } catch (error) {
                console.error('Failed to parse hostContextJson', error);
            }
        }

        // Use the propHostContext if it's provided, otherwise use the one from localStorage
        const storedHostContextString = localStorage.getItem('hostContext');
        const defaultHostContext: HostContext =
        hostContextFromUrl ?? (storedHostContextString ? (JSON.parse(storedHostContextString) as HostContext) : {});

        // Check if defaultHostContext is not empty
        const isDefaultHostContextNotEmpty = Object.keys(defaultHostContext).length > 0;

        if (!isDefaultHostContextNotEmpty) {
            setHostContextState(defaultHostContext);
        }
    }, [hostContextInitialized, setHostContextState]);

    const { activeUserInfo, isMaintenance, embeddedMode } = useAppSelector((state: RootState) => state.app);
    const { t } = useTranslation();

    const chat = useChat();

    useEffect(() => {
        document.title = t('app.title');
        if (isMaintenance && appState !== AppState.ProbeForBackend) {
            setAppState(AppState.ProbeForBackend);
            return;
        }

        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const embedded = params.get('embedded');

        if (embedded === 'true' || window.self !== window.top) {
            dispatch(setEmbeddedMode(true));
        }

        if (auth.isAuthenticated) {
            if (appState === AppState.SettingUserInfo && activeUserInfo === undefined) {
                setAppState(AppState.LoadingChats);
            } else if (appState === AppState.SettingUserInfo) {
                setAppState(AppState.LoadingChats);
            }
        }

        if ((auth.isAuthenticated || AuthHelper.getAuthType() == AuthType.None) && appState === AppState.LoadingChats) {
            console.log('Loading chats and getting service info');
            void Promise.all([
                // Load all chats from memory
                chat.loadChats(hostContext).then((succeeded) => {
                    if (succeeded) {
                        setAppState(AppState.Chat);
                    } else {
                        setAppState(AppState.SigningOut);
                    }
                }),
                // Load service information
                chat.getServiceInfo(hostContext).then((serviceInfo) => {
                    if (serviceInfo) {
                        console.log('Received service info:', serviceInfo);
                        dispatch(setServiceInfo(serviceInfo));
                        const serializableFeatureFlags = Object.fromEntries(
                            Object.entries(serviceInfo.featureFlags).map(([key, value]) => [key, !!value]),
                        );
                        console.log(
                            'Sending featureflagsreceived message with feature flags:',
                            serializableFeatureFlags,
                        );
                        window.parent.postMessage(
                            {
                                event: 'featureflagsreceived',
                                data: { featureFlags: serializableFeatureFlags },
                            },
                            '*',
                        );
                    } else {
                        console.warn('No service info received');
                    }
                }),
            ]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isAuthenticated, appState, hostContext]);

    useEffect(() => {
        if (appState === AppState.SettingUserInfo) {
            console.log('Fetching service info from SettingUserInfo');
            chat.getServiceInfo(hostContext)
                .then((serviceInfo) => {
                    if (serviceInfo) {
                        console.log('Received service info:', serviceInfo);
                        dispatch(setServiceInfo(serviceInfo));
                        const serializableFeatureFlags = Object.fromEntries(
                            Object.entries(serviceInfo.featureFlags).map(([key, value]) => [key, !!value]),
                        );
                        console.log(
                            'Sending featureflagsreceived message with feature flags:',
                            serializableFeatureFlags,
                        );
                        window.parent.postMessage(
                            {
                                event: 'featureflagsreceived',
                                data: { featureFlags: serializableFeatureFlags },
                            },
                            '*',
                        );
                    } else {
                        console.warn('No service info received');
                    }
                })
                .catch((error: unknown) => {
                    console.error('Error fetching service info:', error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hostContext]);

    if (auth.isLoading) {
        return <Loading text={t('app.loading')} />;
    }

    return (
        <FluentProvider className="app-container" theme={semanticKernelLightTheme}>
            {AuthHelper.getAuthType() == AuthType.OIDC && (
                <>
                    {!auth.isAuthenticated ? (
                        <div className={classes.container}>
                            <div className={classes.header}>
                                <Subtitle1 as="h1">{t('app.subTitle')}</Subtitle1>
                            </div>
                            {appState === AppState.SigningOut && <Loading text={t('app.signingOut')} />}
                            {appState !== AppState.SigningOut && <Login />}
                        </div>
                    ) : (
                        <Chat
                            classes={classes}
                            appState={appState}
                            setAppState={setAppState}
                            showHeader={!embeddedMode}
                        />
                    )}
                </>
            )}
            {AuthHelper.getAuthType() == AuthType.None && (
                <Chat classes={classes} appState={appState} setAppState={setAppState} showHeader={!embeddedMode} />
            )}
        </FluentProvider>
    );
};

const Chat = ({
    classes,
    appState,
    setAppState,
    showHeader,
}: {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: React.Dispatch<React.SetStateAction<AppState>>;
    showHeader: boolean;
}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const showCsat = useAppSelector((state: RootState) => state.app.showCsat);

    useMessageHandler({
        setAppState,
        i18n,
        setShowCsat: (show: boolean) => dispatch(setShowCsat(show)),
    });

    return (
        <div className={classes.container}>
            <div className={classes.header} style={{ display: showHeader ? '' : 'none' }}>
                <img src={logo} />
                <Subtitle1 as="h1">{t('app.title')}</Subtitle1>
                {showHeader && <HostContextSelector />}
                <div className={classes.cornerItems}>
                    <div className={classes.cornerItems}>
                        <UserSettingsMenu
                            setLoadingState={() => {
                                setAppState(AppState.SigningOut);
                            }}
                        />
                    </div>
                </div>
            </div>
            {appState === AppState.ProbeForBackend && (
                <BackendProbe
                    uri={process.env.REACT_APP_BACKEND_URI as string}
                    onBackendFound={() => {
                        if (AuthHelper.getAuthType() == AuthType.None) {
                            setAppState(AppState.LoadingChats);
                        } else {
                            setAppState(AppState.SettingUserInfo);
                        }
                    }}
                />
            )}
            {appState === AppState.SettingUserInfo && <Loading text={t('app.loadingUserInfo')} />}
            {appState === AppState.ErrorLoadingUserInfo && <Error text={t('error.general')} />}
            {appState === AppState.LoadingChats && <Loading text={t('app.loadingChats')} />}
            {appState === AppState.Chat && (
                <>
                    {showCsat ? (
                        <CSATDialog
                            isOpen={showCsat}
                            onClose={() => {
                                dispatch(setShowCsat(false));
                                window.parent.postMessage({ event: 'hideCsat' }, '*');
                            }}
                        />
                    ) : (
                        <ChatView setAppState={setAppState} />
                    )}
                </>
            )}
            <WelcomeDialog />
        </div>
    );
};

export default App;
