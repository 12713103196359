interface VersionInfo {
    version: string;
    buildTime: string;
    commitSha: string;
    commitTime: string;
}

export const versionInfo: VersionInfo = {
    version: process.env.REACT_APP_VERSION ?? 'Unknown',
    buildTime: process.env.REACT_APP_BUILD_TIME ?? 'Unknown',
    commitSha: process.env.REACT_APP_COMMIT_SHA ?? 'Unknown',
    commitTime: process.env.REACT_APP_COMMIT_TIME ?? 'Unknown',
};
