import { Link } from '@fluentui/react-components';
import React, { AnchorHTMLAttributes, FC } from 'react';
import { IChatMessage } from '../../libs/models/ChatMessage';

interface CustomLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    href?: string;
    children?: React.ReactNode;
    message: IChatMessage;
}

const CustomLink: FC<CustomLinkProps> = React.memo(
    ({ href, children, ...props }) => {
        return (
            <Link href={href} {...props}>
                {children}
            </Link>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.href === nextProps.href &&
            prevProps.children === nextProps.children &&
            prevProps.message === nextProps.message &&
            prevProps.message.attachments === nextProps.message.attachments
        );
    },
);

CustomLink.displayName = 'CustomLink';

export default CustomLink;
