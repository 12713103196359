import React, { useState } from 'react';
import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    DialogActions,
    Button,
    Label,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { Star24Regular, Star24Filled } from '@fluentui/react-icons';
import { useChat } from '../../libs/hooks/useChat';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    starContainer: {
        display: 'flex',
        gap: '14px',
        marginTop: tokens.spacingVerticalS,
        marginBottom: tokens.spacingVerticalL,
        justifyContent: 'center',
    },
    starButton: {
        background: 'none',
        ...shorthands.border('none'),
        cursor: 'pointer',
        padding: 0,
        transition: 'transform 0.2s ease',
        '&:hover': {
            transform: 'scale(1.1)',
            color: '#FE796B', // Coral 1 100%
        },
    },
    questionContainer: {
        marginBottom: tokens.spacingVerticalL,
        textAlign: 'center',
        '& label': {
            color: '#48237D', // Dark Purple 100%

            fontWeight: tokens.fontWeightSemibold,
        },
    },
    successMessage: {
        color: '#48237D', // Dark Purple 100%
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightSemibold,
        textAlign: 'center',
        marginTop: tokens.spacingVerticalL,
        marginBottom: tokens.spacingVerticalL,
    },
    dialogSurface: {
        backgroundColor: '#FDFEE3', // Vanilla 100%
        ...shorthands.borderRadius('12px'),
        ...shorthands.padding('24px'),
    },
    dialogTitle: {
        color: '#48237D', // Dark Purple 100%
        textAlign: 'center',
        fontSize: tokens.fontSizeBase400,
        fontWeight: tokens.fontWeightSemibold,
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'center',

        marginTop: tokens.spacingVerticalL,
    },
    primaryButton: {
        backgroundColor: '#48237D', // Dark Purple 100%
        color: '#FDFEE3', // Vanilla 100%
        '&:hover': {
            backgroundColor: '#6B5097', // Dark Purple 80%
        },
        '&:disabled': {
            backgroundColor: '#A291BD', // Dark Purple 50%
        },
    },
    secondaryButton: {
        backgroundColor: '#FFF1F0', // Coral 1 10%
        color: '#FE796B', // Coral 1 100%
        '&:hover': {
            backgroundColor: '#FFBBB4', // Coral 1 50%
        },
    },
});

interface CSATDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

export const CSATDialog: React.FC<CSATDialogProps> = ({ isOpen, onClose }) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const [rating, setRating] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const chat = useChat();

    const handleSubmit = async () => {
        if (rating) {
            setSubmitted(true);
            await chat.submitCsatRatings(rating);
            setTimeout(() => {
                onClose();
            }, 1500);
        }
    };

    const handleDecline = async () => {
        await chat.declineCsat();
        onClose();
    };

    const StarRating: React.FC<{ value: number; onChange: (value: number) => void }> = ({ value, onChange }) => (
        <div className={styles.starContainer}>
            {[1, 2, 3, 4, 5].map((star) => (
                <button
                    data-testid={`csat-star-${star.toString()}`}
                    key={`csat-star-${star.toString()}`}
                    onClick={() => {
                        onChange(star);
                    }}
                    className={styles.starButton}
                    type="button"
                >
                    {star <= value ? (
                        <Star24Filled style={{ color: tokens.colorPaletteYellowForeground1 }} />
                    ) : (
                        <Star24Regular />
                    )}
                </button>
            ))}
        </div>
    );

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(_, { open }) => {
                if (!open) onClose();
            }}
        >
            <DialogSurface className={styles.dialogSurface}>
                <DialogBody>
                    <DialogTitle className={styles.dialogTitle} data-testid="csat-title">
                        {t('csat.title')}
                    </DialogTitle>
                    <DialogContent>
                        {!submitted ? (
                            <>
                                <div className={styles.questionContainer}>
                                    <Label>{t('csat.satisfactionQuestion')}</Label>
                                    <StarRating value={rating} onChange={setRating} />
                                </div>

                                <DialogActions className={styles.actionButtons}>
                                    <Button
                                        className={styles.secondaryButton}
                                        onClick={() => {
                                            void handleDecline();
                                        }}
                                        data-testid="csat-decline"
                                    >
                                        {t('csat.decline')}
                                    </Button>
                                    <Button
                                        className={styles.primaryButton}
                                        onClick={() => {
                                            void handleSubmit();
                                        }}
                                        disabled={!rating}
                                        data-testid="csat-submit"
                                    >
                                        {t('csat.submit')}
                                    </Button>
                                </DialogActions>
                            </>
                        ) : (
                            <div className={styles.successMessage}>{t('csat.thankYou')}</div>
                        )}
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
