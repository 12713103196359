import { AuthProvider, useAuth } from 'react-oidc-context';
import { FluentProvider } from '@fluentui/react-components';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import App from './App';
import { Constants } from './Constants';
import MissingEnvVariablesError from './components/views/MissingEnvVariablesError';
import './index.css';
import { AuthHelper, AuthType } from './libs/auth/AuthHelper';
import { store } from './redux/app/store';
import React, { useEffect } from 'react';
import { getMissingEnvVariables } from './checkEnv';
import { semanticKernelLightTheme } from './styles';
import { HostContext } from './embed-script/HostContext';
import hasOwn from 'hasown';
import * as tracking from './libs/tracking';
import { FeatureFlagInitializer } from './components/FeatureFlagInitializer';
import { setActiveUserInfo, setAuthToken } from './redux/features/app/appSlice';
import { User, WebStorageStateStore } from 'oidc-client-ts';

if (!localStorage.getItem('debug')) {
    localStorage.setItem('debug', `${Constants.debug.root}:*`);
}

// Polyfill for Object.hasOwn
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!Object.hasOwn) {
    Object.hasOwn = function (obj, key) {
        return hasOwn(obj, key);
    };
}

tracking.init();

function removeOidcParameters(url: URL) {
    url.searchParams.delete('code');
    url.searchParams.delete('scope');
    url.searchParams.delete('session_state');
    url.searchParams.delete('iss');
    url.searchParams.delete('state');
    url.searchParams.delete('loggedout');
    url.searchParams.delete('hostContext');
    return url;
}

const oidcConfig = {
    accessTokenExpiringNotificationTimeInSeconds: 60,
    metadataUrl: process.env.REACT_APP_OPENIDCONNECT_METADATA_URL,
    authority: process.env.REACT_APP_OPENIDCONNECT_AUTHORITY ?? '',
    client_id: process.env.REACT_APP_OPENIDCONNECT_CLIENT_ID ?? '',
    redirect_uri: process.env.REACT_APP_OPENIDCONNECT_REDIRECT_URI ?? '',
    post_logout_redirect_uri: process.env.REACT_APP_OPENIDCONNECT_REDIRECT_URI,
    response_type: 'code',
    scope: `openid profile email roles ${process.env.REACT_APP_OPENIDCONNECT_API_SCOPE ?? ''} offline_access`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    onSigninCallback: (_user: User | undefined): void => {
        const url = new URL(window.location.href);
        window.history.replaceState({}, document.title, removeOidcParameters(url).toString());
    },
};

let container: HTMLElement | null = null;
document.addEventListener('DOMContentLoaded', () => {
    (async () => {
        await new Promise((resolve) => setTimeout(resolve, 1));

        if (!container) {
            container = document.getElementById('root');
            if (!container) {
                throw new Error('Could not find root element');
            }
            const root = ReactDOM.createRoot(container);

            const missingEnvVariables = getMissingEnvVariables();
            const validEnvFile = missingEnvVariables.length === 0;
            const shouldUseNone = validEnvFile && AuthHelper.getAuthType() === AuthType.None;

            root.render(
                <React.StrictMode>
                    {validEnvFile ? (
                        <ReduxProvider store={store}>
                            <FeatureFlagInitializer />
                            {shouldUseNone && <AppWithTheme />}
                            {!shouldUseNone && (
                                <AuthProvider {...oidcConfig}>
                                    {' '}
                                    <AppWithTheme />{' '}
                                </AuthProvider>
                            )}
                        </ReduxProvider>
                    ) : (
                        <MissingEnvVariablesError missingVariables={missingEnvVariables} />
                    )}
                </React.StrictMode>,
            );
        }
    })().catch((e: unknown) => {
        console.error(e);
    });
});

function AppWithAuth() {
    const Auth = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (!Auth.isAuthenticated && !(window.location.pathname === '/' && urlParams.get('code'))) {
            const lastlogin = localStorage.getItem('oidcAutoLastLogin');
            let performAutoLogin = true;

            if (lastlogin) {
                const lastloginDate = new Date(lastlogin);
                const now = new Date();
                const diff = now.getTime() - lastloginDate.getTime();
                if (diff < 30000) {
                    performAutoLogin = false;
                }
            }
            if (performAutoLogin) {
                localStorage.setItem('oidcAutoLastLogin', new Date().toISOString());
                void Auth.signinSilent();
            }
        }
    }, [Auth]);

    useEffect(() => {
        dispatch(setAuthToken(Auth.user?.access_token ?? null));
        if (Auth.isAuthenticated && Auth.user) {
            dispatch(
                setActiveUserInfo({
                    id: Auth.user.profile.sub,
                    email: Auth.user.profile.email ?? '',
                    username: Auth.user.profile.name ?? '',
                    sessionId: AuthHelper.getSessionIdFromToken(Auth.user.access_token),
                }),
            );
        }
    }, [Auth, dispatch]);

    useEffect(() => {
        if (Auth.isAuthenticated) {
            AuthHelper.setLogoutFunction(async () => {
                try {
                    localStorage.setItem('oidcAutoLastLogin', new Date().toISOString());
                    await Auth.removeUser();
                    await Auth.signoutRedirect();
                    const url = new URL(window.location.href);
                    url.searchParams.set('loggedout', 'true');
                    window.location.href = url.toString();
                } catch (error) {
                    console.error('Error during logout:', error);
                }
            });
        }
    }, [Auth.isAuthenticated, Auth]);

    return <App />;
}

interface AppWithThemeProps {
    hostContext?: HostContext;
}

const AppWithTheme: React.FC<AppWithThemeProps> = () => {
    return (
        <FluentProvider className="app-container" theme={semanticKernelLightTheme}>
            <AppWithAuth />
        </FluentProvider>
    );
};
